import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import Section from "../components/section"

const DefList = styled.dl`
  div {
    margin-bottom: 1rem;
    dt::after {
      content: ":";
    }
    dd {
      margin-bottom: 0.15rem;
    }

    @media (min-width: 768px) {
      dt {
        float: left;
        clear: left;
        text-align: right;
        width: 8rem;
      }
      dd {
        margin: 0 0 0 8.5rem;
      }
    }
  }
`

const Link = styled.a`
  :hover {
    text-decoration: none;
    transition: 250ms;
  }
`

const AboutPage = () => (
  <Layout>
    <Section>
      <h2 className="about-header">About</h2>
      <div className="wrapper">
        <p>
          Ryan O'Shea is a Software Engineer from Dalry, Scotland who has lived
          and worked in Berlin, Germany since 2003.
        </p>
        <p>
          He loves to build, learn and fix things. Software engineering gives
          him daily satisfaction, since there is always something new to make,
          or bugs to fix.
        </p>
        <p>
          Before software engineering, Ryan had a 12 year career in edtech
          with&nbsp;
          <Link
            href="https://www.wallstreetenglish.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Wall Street English
          </Link>{" "}
          and&nbsp;
          <Link
            href="https://www.pearson.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Pearson
          </Link>{" "}
          as a manager, trainer and teacher.
        </p>
        <p>
          He also has a education and training in architectural design from
          the&nbsp;
          <Link
            href="https://www.strath.ac.uk/engineering/architecture/"
            target="_blank"
            rel="noopener noreferrer"
          >
            University of Strathcylde
          </Link>{" "}
          in Glasgow.
        </p>
        <p>
          <b>Fun fact</b>: if he's running a workshop with a warm-up where the
          introduction needs a "fun fact", he usually says something
          about:&nbsp;
          <Link
            href="https://en.wikipedia.org/wiki/Sandor_Clegane"
            target="_blank"
            rel="noopener noreferrer"
          >
            Sandor Clegane
          </Link>
          ,&nbsp;
          <Link
            href="https://youtu.be/XJFPuz3MXho?t=12"
            target="_blank"
            rel="noopener noreferrer"
          >
            Pete Doherty
          </Link>
          , or&nbsp;
          <Link
            href="https://youtu.be/LeMSz2v46Ok"
            target="_blank"
            rel="noopener noreferrer"
          >
            Max Zander
          </Link>
          .
        </p>
      </div>
    </Section>
    <Section>
      <h2 className="about-header">Tech Stack</h2>
      <DefList className="wrapper">
        <p>
          Since making the decision to develop a third career, Ryan has added a
          set of tools and skills needed to work on web applications.
        </p>
        <div>
          <dt>Core</dt>
          <dd>HTML5, CSS3, Javascript, Typescript</dd>
        </div>
        <div>
          <dt>Client-side</dt>
          <dd>
            SASS, Bootstrap, Chakra, MUI, styled-components, React, Redux, Next,
            Gatsby, Vue, Vuex
          </dd>
        </div>
        <div>
          <dt>Server-side</dt>
          <dd>Node, Express, Prisma, tRPC, MongoDB, Mongoose, Postgres</dd>
        </div>
        <div>
          <dt>Dev Tools</dt>
          <dd>Ubuntu, MacOS, VSCode, Bash, Git</dd>
        </div>
        <div>
          <dt>Deployment</dt>
          <dd>Netlify, Vercel</dd>
        </div>
        <div>
          <dt>Methods</dt>
          <dd>Scrum, Kanban, Wardley Maps</dd>
        </div>
      </DefList>
    </Section>
    <Section>
      <h2 className="about-header">Resume</h2>
      <div className="wrapper">
        <p>
          This is the short version of Ryan's career and is optimised to support
          your decision making process. For more depth see his&nbsp;
          <Link
            href="https://linkedin.com/in/ryanjamesoshea/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Linkedin
          </Link>{" "}
          page.
        </p>
        <DefList>
          <div>
            <dt>Development</dt>
            <dd>June 2022 - present</dd>
            <dd>Junior Software Engineer</dd>
            <dd>
              <Link
                href="https://holobuilder.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                HoloBuilder
              </Link>
              , Aachen
            </dd>
          </div>
          <div>
            <dd>August 2019 - May 2022</dd>
            <dd>Freelance Web Developer</dd>
            <dd>Berlin</dd>
          </div>
          <div>
            <dd>July 2018 - July 2019</dd>
            <dd>Web Development Trainee</dd>
            <dd>
              <Link
                href="https://digitalcareerinstitute.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Digital Career Institute
              </Link>
              , Berlin
            </dd>
          </div>
          <div>
            <dt>Management</dt>
            <dd>September 2005 - December 2016</dd>
            <dd>School Manager</dd>
            <dd>Wall Street Institute, Berlin</dd>
          </div>
          <div>
            <dt>Design</dt>
            <dd>October 1994 - June 1999</dd>
            <dd>BSc Architectural Studies</dd>
          </div>
        </DefList>
      </div>
    </Section>
  </Layout>
)

export default AboutPage
